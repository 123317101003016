import { useMutation } from "@apollo/client";

import useCommunityMeCacheUpdater from "@hooks/useCommunityMeCacheUpdater";
import useMe from "@hooks/useMe";
import {
  CommunityUserCreateDocument,
  CommunityUserCreateMutation,
  CommunityUserCreateMutationVariables
} from "@typing/Generated";

const useCreateCommunityUser = () => {
  const me = useMe();
  const updateCommunityMeCache = useCommunityMeCacheUpdater();

  const [createCommunityUser] = useMutation<CommunityUserCreateMutation, CommunityUserCreateMutationVariables>(
    CommunityUserCreateDocument,
    {
      update: (store, { data }) => {
        const user = data?.createCommunityUser?.user;
        updateCommunityMeCache(store, user);
      },
      variables: { firstName: me?.firstName, lastName: me?.lastName }
    }
  );

  return createCommunityUser;
};

export default useCreateCommunityUser;
