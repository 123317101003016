import { useCallback, useContext } from "react";

import { sessionContext } from "@context/Contexts";

const useActiveToken = () => {
  const { sessionState } = useContext(sessionContext);

  const getActiveToken = useCallback(
    (overrideToken?: string | null) => {
      const impersonatingExpertAuthToken = sessionState.impersonateExpertAuthToken;
      const impersonatingClientAuthToken = sessionState.impersonateClientAuthToken;
      const authToken = sessionState.authToken;

      return overrideToken ?? impersonatingClientAuthToken ?? impersonatingExpertAuthToken ?? authToken;
    },
    [sessionState.authToken, sessionState.impersonateClientAuthToken, sessionState.impersonateExpertAuthToken]
  );

  return getActiveToken;
};

export default useActiveToken;
