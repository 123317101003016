import { ApolloCache } from "@apollo/client";
import { useContext } from "react";

import { setCommunityMe } from "@actions/sessionActions";
import { sessionContext } from "@context/Contexts";
import { CommunityFullUserFragment, GetCommunityMeDocument, GetCommunityMeQuery } from "@typing/Generated";

const useCommunityMeCacheUpdater = () => {
  const session = useContext(sessionContext);

  const updateCommunityMeCache = (store: ApolloCache<any>, user: CommunityFullUserFragment | null | undefined) => {
    const cacheData = store.readQuery<GetCommunityMeQuery>({
      query: GetCommunityMeDocument
    });

    // This is a bit weird, but because the getCommunityMe query is run lazily, the query
    // doesn't update even when we update the cache associated with it below. Thus, the
    // GetCommunityMe component won't run the effect to update the copy of the communityMe
    // user in the session. Dispatching a session update here seems a bit hacky but it does
    // work and the alternative of executing the query non-lazily has other disgusting bugs
    // associated with it.
    if (user) {
      session.dispatch(setCommunityMe(user));
    }

    if (user && cacheData) {
      const newCacheData = {
        ...cacheData,
        communityCurrentUser: {
          ...cacheData.communityCurrentUser,
          ...user
        }
      };

      store.writeQuery<GetCommunityMeQuery>({
        data: newCacheData,
        query: GetCommunityMeDocument
      });
    }
  };

  return updateCommunityMeCache;
};

export default useCommunityMeCacheUpdater;
