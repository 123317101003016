import { ReactElement, ReactNode, useCallback, useEffect, useState } from "react";
import { BooleanParam, useQueryParams, withDefault } from "use-query-params";

import { openerContext } from "@context/Contexts";
import { OpenerTypeEnum } from "@typing/Enums";
import { ResourceFragment as Resource, WorksheetFragment } from "@typing/Generated";
import { OpenerState } from "@typing/State";

const initialState: OpenerState = Object.values(OpenerTypeEnum).map(type => ({
  isOpen: false,
  record: undefined,
  type
}));

const OpenerStore = ({ children }: { children: ReactNode }): ReactElement => {
  const [openerState, setOpenerState] = useState(initialState);

  const [query, setQuery] = useQueryParams({
    displayMessages: withDefault({ ...BooleanParam, urlName: "d_msg" }, false),
    displayTasks: withDefault({ ...BooleanParam, urlName: "d_tasks" }, false),
    displayTimeLog: withDefault({ ...BooleanParam, urlName: "d_timelog" }, false),
    displayUniversalSearch: withDefault({ ...BooleanParam, urlName: "d_search" }, false)
  });

  const toggleOpener = useCallback(
    (type: OpenerTypeEnum, isOpen: boolean, record?: Resource | WorksheetFragment) => {
      if (type === OpenerTypeEnum.MESSAGES && isOpen !== query.displayMessages) {
        setQuery({ displayMessages: isOpen }, "replaceIn");
      } else if (type === OpenerTypeEnum.TASKS && isOpen !== query.displayTasks) {
        setQuery({ displayTasks: isOpen }, "replaceIn");
      } else if (type === OpenerTypeEnum.TIME_LOG && isOpen !== query.displayTimeLog) {
        setQuery({ displayTimeLog: isOpen }, "replaceIn");
      } else if (type === OpenerTypeEnum.UNIVERSAL_SEARCH && isOpen !== query.displayUniversalSearch) {
        setQuery({ displayUniversalSearch: isOpen }, "replaceIn");
      }

      setOpenerState(prevState =>
        prevState.map(opener =>
          opener.type === type
            ? {
                ...opener,
                isOpen,
                record
              }
            : opener
        )
      );
    },
    [query.displayMessages, query.displayTasks, query.displayTimeLog, query.displayUniversalSearch, setQuery]
  );

  useEffect(() => {
    const messageState = openerState.find(item => item.type === OpenerTypeEnum.MESSAGES);
    const taskState = openerState.find(item => item.type === OpenerTypeEnum.TASKS);
    const timeLogState = openerState.find(item => item.type === OpenerTypeEnum.TIME_LOG);
    const universalSearchState = openerState.find(item => item.type === OpenerTypeEnum.UNIVERSAL_SEARCH);

    if (query.displayMessages !== messageState?.isOpen) {
      toggleOpener(OpenerTypeEnum.MESSAGES, query.displayMessages);
    }
    if (query.displayTasks !== taskState?.isOpen) {
      toggleOpener(OpenerTypeEnum.TASKS, query.displayTasks);
    }
    if (query.displayTimeLog !== timeLogState?.isOpen) {
      toggleOpener(OpenerTypeEnum.TIME_LOG, query.displayTimeLog);
    }
    if (query.displayUniversalSearch !== universalSearchState?.isOpen) {
      toggleOpener(OpenerTypeEnum.UNIVERSAL_SEARCH, query.displayUniversalSearch);
    }
  }, [
    openerState,
    query.displayMessages,
    query.displayTasks,
    query.displayTimeLog,
    query.displayUniversalSearch,
    toggleOpener
  ]);

  return <openerContext.Provider value={{ openerState, toggleOpener }}>{children}</openerContext.Provider>;
};

export default OpenerStore;
