import { relayStylePagination } from "@apollo/client/utilities";

const relayPaginationPolicies = {
  activities: relayStylePagination(["activityKinds", "actorKind", "clientId", "expertId", "section", "startTime"]),
  admins: relayStylePagination([]),
  articles: relayStylePagination([
    "completedWebinars",
    "contentConditionKinds",
    "contentTypes",
    "focusArea",
    "hostedByGather",
    "imminentWebinars",
    "includeHidden",
    "recurringWebinarInclusion",
    "search",
    "sortBy",
    "sortDirection",
    "upcomingWebinars",
    "useCase"
  ]),
  automationApplications: relayStylePagination(["automationId", "journeyId"]),
  automations: relayStylePagination(["searchTerm", "sortBy", "sortDirection"]),
  carePlanTaskTemplates: relayStylePagination(["focusArea", "searchTerm", "sortBy", "sortDirection", "userType"]),
  careProfileChanges: relayStylePagination(["endDate", "journeyId", "startDate"]),
  chatMessages: relayStylePagination(["id"]),
  communityGroupMembers: relayStylePagination(["groupId", "search"]),
  communityGroups: relayStylePagination(["ids", "membershipTypes", "mine", "search", "sortBy", "sortDirection"]),
  communityNotifications: relayStylePagination(["isRead"]),
  communityPosts: relayStylePagination([
    "authorId",
    "comments",
    "groupId",
    "myGroups",
    "reported",
    "sortBy",
    "sortDirection"
  ]),
  communityReactions: relayStylePagination(["sortBy", "sortDirection"]),
  communityUsers: relayStylePagination(["grayceUserIds", "grayceUserTypes", "search", "sortBy", "sortDirection"]),
  drugsSearch: relayStylePagination(["count", "search"]),
  eligibilityFiles: relayStylePagination(["employerId"]),
  emailMessages: relayStylePagination([]),
  employees: relayStylePagination(["employerId", "search", "sortBy", "sortDirection"]),
  employerBenefits: relayStylePagination(["category", "journeyId", "search"]),
  employers: relayStylePagination(["searchTerm", "sortBy", "sortDirection"]),
  expertAiChatThreads: relayStylePagination([]),
  expertCarePlanTurnAroundTimes: relayStylePagination(["endDate", "expertId", "sortBy", "sortDirection", "startDate"]),
  expertCarePlans: relayStylePagination([
    "expertArchived",
    "expertId",
    "icrStatus",
    "sortBy",
    "sortDirection",
    "status"
  ]),
  expertClients: relayStylePagination([
    "endDate",
    "expertId",
    "primaryJourneys",
    "searchTerm",
    "sortBy",
    "sortDirection",
    "startDate"
  ]),
  expertConsults: relayStylePagination([
    "consultReportStatus",
    "duration",
    "employerId",
    "endDate",
    "expertId",
    "forExpert",
    "needsInterpretation",
    "position",
    "selfPaid",
    "sortBy",
    "sortDirection",
    "startDate",
    "timePeriod"
  ]),
  expertJourneys: relayStylePagination([
    "allJourneys",
    "employerId",
    "expertArchived",
    "expertId",
    "expertUnopened",
    "primaryExpertId",
    "role",
    "search",
    "selfPaid",
    "sortBy",
    "sortDirection",
    "statuses"
  ]),
  expertNeedsResponseChats: relayStylePagination(["expertId"]),
  expertOverviews: relayStylePagination(["endDate", "expertId", "sortBy", "sortDirection", "startDate"]),
  expertTasks: relayStylePagination([
    "archived",
    "assignee",
    "clientId",
    "completed",
    "endTime",
    "expertId",
    "forTime",
    "journeyId",
    "onlyGoalTasks",
    "sortBy",
    "sortDirection",
    "startTime",
    "status"
  ]),
  experts: relayStylePagination(["searchTerm", "sortBy", "sortDirection"]),
  goalTemplates: relayStylePagination([
    "archived",
    "category",
    "countryCode",
    "focusArea",
    "searchTerm",
    "sortBy",
    "sortDirection",
    "subcategory",
    "useCase"
  ]),
  hotlines: relayStylePagination(["search"]),
  internalNoteTemplates: relayStylePagination(["searchTerm", "sortBy", "sortDirection"]),
  journeyMessages: relayStylePagination(["id", "search"]),
  journeyTaggings: relayStylePagination([
    "employerId",
    "endDate",
    "expertId",
    "searchTerm",
    "selfPaid",
    "sortBy",
    "sortDirection",
    "startDate",
    "status",
    "tag"
  ]),
  journeyTags: relayStylePagination([
    "employerId",
    "endDate",
    "expertId",
    "searchTerm",
    "selfPaid",
    "sortBy",
    "sortDirection",
    "startDate"
  ]),
  journeyTasks: relayStylePagination(["assignee", "journeyId", "status"]),
  journeys: relayStylePagination([
    "category",
    "consultComplete",
    "employerId",
    "expertId",
    "search",
    "selfPaid",
    "skipIncompleteSecondary",
    "skipUnassigned",
    "sortBy",
    "sortDirection"
  ]),
  medicalConditionSearch: relayStylePagination(["count", "search"]),
  messageTemplateCategories: relayStylePagination(["search", "sortBy", "sortDirection"]),
  messageTemplates: relayStylePagination(["categoryId", "search", "sortBy", "sortDirection"]),
  messages: relayStylePagination([]),
  myCalendarEvents: relayStylePagination(["endTime", "startTime"]),
  myChats: relayStylePagination(["journeyId"]),
  myMessagesSearch: relayStylePagination(["journeyId", "search"]),
  oidcProviders: relayStylePagination([]),
  outOfOfficePeriods: relayStylePagination(["endDate", "startDate"]),
  packageGrants: relayStylePagination(["eligibilityStatus", "employerId", "selfPaid", "sortBy", "sortDirection"]),
  peerSupportRequests: relayStylePagination([]),
  resourceFeatureTemplates: relayStylePagination(["search"]),
  surveyResponses: relayStylePagination(["employerId", "endDate", "expertId", "service", "startDate"]),
  timeLogs: relayStylePagination(["billable", "endDate", "expertId", "group", "journeyId", "startDate"]),
  webinarRegistrations: relayStylePagination(["sortBy", "sortDirection", "webinarId"]),
  worksheetTemplates: relayStylePagination(["archived", "focusArea", "searchTerm", "sortBy", "sortDirection"])
};

export default relayPaginationPolicies;
