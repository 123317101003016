import { useLazyQuery } from "@apollo/client";
import { datadogLogs } from "@datadog/browser-logs";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { setCommunityMe } from "@actions/sessionActions";
import { sessionContext } from "@context/Contexts";
import useActiveToken from "@hooks/useActiveToken";
import useCreateCommunityUser from "@hooks/useCreateCommunityUser";
import useMe from "@hooks/useMe";
import { GetCommunityMeDocument, GetCommunityMeQuery, GetCommunityMeQueryVariables } from "@typing/Generated";
import { isNotNullOrUndefined } from "@utils/arrayUtils";

const GetCommunityMe = () => {
  const session = useContext(sessionContext);
  const getActiveToken = useActiveToken();
  const me = useMe();
  const authToken = useMemo(() => getActiveToken(), [getActiveToken]);
  const createCommunityUser = useCreateCommunityUser();
  const [authTokenWas, setAuthTokenWas] = useState(authToken);
  const [found, setFound] = useState<boolean | undefined>(undefined);

  const [getCommunityMeQuery] = useLazyQuery<GetCommunityMeQuery, GetCommunityMeQueryVariables>(
    GetCommunityMeDocument,
    { fetchPolicy: "network-only" }
  );

  const findCommunityMe = useCallback(() => {
    getCommunityMeQuery()
      .then(response => {
        const user = response.data?.communityCurrentUser;
        if (isNotNullOrUndefined(user)) {
          session.dispatch(setCommunityMe(user));
          setFound(true);
        } else {
          setFound(false);
        }
      })
      .catch(error => {
        datadogLogs.logger.error("Error fetching community me", { error });
        session.dispatch(setCommunityMe(null));
        setFound(false);
      });
  }, [getCommunityMeQuery, session]);

  // We only want to try to find the community user if the monolith user has a firstName, because if
  // the monolith user does not yet have a firstName that means we're early in registration and the
  // community user won't exist yet. As such, we don't want to set found to false when we know we
  // won't find it because that will trigger a creation attempt that might conflict with the user
  // creation that happens automatically during the registration process.
  useEffect(() => {
    if ((authTokenWas !== authToken || (authToken && found === undefined)) && me?.firstName) {
      setAuthTokenWas(authToken);
      if (authToken) {
        findCommunityMe();
      } else {
        session.dispatch(setCommunityMe(null));
        setFound(undefined);
      }
    }
  }, [authToken, authTokenWas, findCommunityMe, found, me?.firstName, session]);

  // Using the found state ensures this only does anything once we've already tried (and failed) to
  // find the community user for the auth token. If that hasn't happened yet, and we have enough
  // data to create the community user (i.e. we have a first name), then we should run the creation
  // code.
  useEffect(() => {
    if (authToken && authToken === authTokenWas && found === false && me?.firstName) {
      createCommunityUser({
        variables: {
          firstName: me.firstName,
          lastName: me.lastName
        }
      })
        .then(response => {
          setFound(true);
          session.dispatch(setCommunityMe(response.data?.createCommunityUser?.user ?? null));
        })
        .catch(error => {
          datadogLogs.logger.error("Error creating community user in GetCommunityMe", { error });
          session.dispatch(setCommunityMe(null));
        });
    }
  }, [
    authToken,
    authTokenWas,
    createCommunityUser,
    found,
    me?.firstName,
    me?.lastName,
    session,
    session.sessionState.communityMe
  ]);

  return null;
};

export default GetCommunityMe;
