const operationVariableTypes: Record<string, Record<string, string>> = {
  ActivityCreate: {
    data: "JSON",
    journeyId: "ID",
    kind: "ActivityKindEnum"
  },
  AdminUpdate: {
    firstName: "String",
    id: "ID",
    lastName: "String",
    timezone: "String",
    zoomId: "String"
  },
  AiChatMessageCreate: {
    aiChatThreadId: "ID",
    message: "String"
  },
  AiWrittenVoiceChange: {
    content: "String"
  },
  AnalyticsEventCreate: {
    eventData: "JSON"
  },
  AppEventCreate: {
    actionKind: "AppEventActionKindEnum",
    context: "String",
    journeyId: "ID",
    resourceId: "ID"
  },
  AppleAuthenticationProcess: {
    accessType: "ClientAccessTypeEnum",
    firstName: "String",
    idToken: "String",
    lastName: "String"
  },
  ArticleCreate: {
    blurb: "String",
    contentType: "ArticleContentTypeEnum",
    countryCodes: "String",
    focusAreas: "FocusAreaEnum",
    hidden: "Boolean",
    hostedByGather: "Boolean",
    photoBlobId: "ID",
    quote: "String",
    sources: "LinkInput",
    tags: "String",
    text: "String",
    title: "String",
    transcript: "String",
    useCases: "CareProfileUseCaseEnum",
    videoBlobId: "ID",
    webinarCompleted: "Boolean",
    webinarDuration: "Int",
    webinarRecurEndTimes: "Int",
    webinarRecurMonthlyDay: "Int",
    webinarRecurMonthlyType: "WebinarRecurMonthlyTypeEnum",
    webinarRecurMonthlyWeek: "WebinarRecurMonthlyWeekEnum",
    webinarRecurMonthlyWeekday: "WebinarRecurWeekdayEnum",
    webinarRecurRepeatInterval: "Int",
    webinarRecurType: "WebinarRecurTypeEnum",
    webinarRecurWeeklyDays: "WebinarRecurWeekdayEnum",
    webinarStartTime: "String"
  },
  ArticleDestroy: {
    id: "ID"
  },
  ArticleShareCreate: {
    articleId: "ID"
  },
  ArticleShareOpened: {
    articleShareId: "ID"
  },
  ArticleUpdate: {
    blurb: "String",
    countryCodes: "String",
    focusAreas: "FocusAreaEnum",
    hidden: "Boolean",
    hostedByGather: "Boolean",
    id: "ID",
    photoBlobId: "ID",
    quote: "String",
    sources: "LinkInput",
    tags: "String",
    text: "String",
    title: "String",
    transcript: "String",
    useCases: "CareProfileUseCaseEnum",
    videoBlobId: "ID",
    webinarCompleted: "Boolean",
    webinarDuration: "Int",
    webinarRecurEndTimes: "Int",
    webinarRecurMonthlyDay: "Int",
    webinarRecurMonthlyType: "WebinarRecurMonthlyTypeEnum",
    webinarRecurMonthlyWeek: "WebinarRecurMonthlyWeekEnum",
    webinarRecurMonthlyWeekday: "WebinarRecurWeekdayEnum",
    webinarRecurRepeatInterval: "Int",
    webinarRecurType: "WebinarRecurTypeEnum",
    webinarRecurWeeklyDays: "WebinarRecurWeekdayEnum",
    webinarStartTime: "String"
  },
  AssessmentStepMarkCompleted: {
    id: "ID",
    skipReason: "AssessmentStepSkipReasonEnum"
  },
  AutomationActionBlockCreate: {
    archiveTag: "String",
    articleId: "ID",
    automationId: "ID",
    carePlanTaskTemplateId: "ID",
    duration: "Int",
    durationUnits: "DurationUnitsEnum",
    goalTemplateId: "ID",
    kind: "AutomationActionBlockKindEnum",
    messageContent: "String",
    parentBlockId: "ID",
    parentBlockOutput: "AutomationBlockOutputEnum",
    pushNotificationAction: "PushNotificationActionEnum",
    pushNotificationContent: "String",
    tag: "String",
    taskDueDays: "Int",
    taskDueHour: "Int",
    taskKind: "TaskKindEnum",
    taskTitle: "String",
    userType: "AutomationUserTypeEnum",
    worksheetTemplateId: "ID"
  },
  AutomationActionBlockDestroy: {
    id: "ID"
  },
  AutomationActionBlockUpdate: {
    archiveTag: "String",
    articleId: "ID",
    carePlanTaskTemplateId: "ID",
    duration: "Int",
    durationUnits: "DurationUnitsEnum",
    goalTemplateId: "ID",
    id: "ID",
    kind: "AutomationActionBlockKindEnum",
    messageContent: "String",
    pushNotificationAction: "PushNotificationActionEnum",
    pushNotificationContent: "String",
    tag: "String",
    taskDueDays: "Int",
    taskDueHour: "Int",
    taskKind: "TaskKindEnum",
    taskTitle: "String",
    userType: "AutomationUserTypeEnum",
    worksheetTemplateId: "ID"
  },
  AutomationConditionBlockCreate: {
    automationId: "ID",
    parentBlockId: "ID",
    parentBlockOutput: "AutomationBlockOutputEnum"
  },
  AutomationConditionBlockDestroy: {
    id: "ID",
    keepBranch: "AutomationConditionBlockKeepBranchEmum"
  },
  AutomationConditionCreate: {
    automationConditionGroupId: "ID",
    careProfileField: "CareProfileActionableFieldEnum",
    careProfileValues: "CareProfileActionableFieldValueEnum",
    duration: "Int",
    durationUnits: "DurationUnitsEnum",
    employerId: "ID",
    kind: "AutomationConditionKindEnum"
  },
  AutomationConditionDestroy: {
    id: "ID"
  },
  AutomationConditionGroupCreate: {
    automationConditionBlockId: "ID",
    operation: "AutomationConditionGroupOperationEnum"
  },
  AutomationConditionGroupDestroy: {
    id: "ID"
  },
  AutomationConditionGroupUpdate: {
    id: "ID",
    operation: "AutomationConditionGroupOperationEnum"
  },
  AutomationConditionUpdate: {
    careProfileField: "CareProfileActionableFieldEnum",
    careProfileValues: "CareProfileActionableFieldValueEnum",
    duration: "Int",
    durationUnits: "DurationUnitsEnum",
    employerId: "ID",
    id: "ID",
    kind: "AutomationConditionKindEnum"
  },
  AutomationCreate: {
    tags: "String",
    title: "String"
  },
  AutomationDestroy: {
    id: "ID"
  },
  AutomationTimingBlockCreate: {
    automationId: "ID",
    duration: "Int",
    durationUnits: "DurationUnitsEnum",
    parentBlockId: "ID",
    parentBlockOutput: "AutomationBlockOutputEnum"
  },
  AutomationTimingBlockDestroy: {
    id: "ID"
  },
  AutomationTimingBlockUpdate: {
    duration: "Int",
    durationUnits: "DurationUnitsEnum",
    id: "ID"
  },
  AutomationTriggerBlockUpdate: {
    activityKind: "ActivityKindEnum",
    articleId: "ID",
    careProfileField: "CareProfileActionableFieldEnum",
    careProfileValues: "CareProfileActionableFieldValueEnum",
    consultReportKind: "ConsultReportKindEnum",
    duration: "Int",
    durationUnits: "DurationUnitsEnum",
    freeTextKeywords: "String",
    frequency: "AutomationTriggerBlockFrequencyEnum",
    goalTemplateId: "ID",
    id: "ID",
    kind: "AutomationTriggerBlockKindEnum",
    signedDocumentKind: "SignedDocumentKindEnum",
    tag: "String",
    taskClientVisible: "TaskClientVisibleEnum",
    taskKind: "TaskKindEnum",
    trackerType: "TrackerTypeEnum",
    userType: "AutomationUserTypeEnum",
    webinarId: "ID"
  },
  AutomationUpdate: {
    id: "ID",
    live: "Boolean",
    tags: "String",
    title: "String"
  },
  BannerDismiss: {
    id: "ID"
  },
  CarePlanActionItemCreate: {
    action: "String",
    assignmentIds: "ID",
    carePlanTaskTemplateId: "ID",
    description: "String",
    goalId: "ID",
    subtext: "String",
    title: "String",
    worksheetTemplateId: "ID"
  },
  CarePlanActionItemDestroy: {
    id: "ID"
  },
  CarePlanActionItemToggleCompleted: {
    completed: "Boolean",
    fileResourceId: "ID",
    id: "ID"
  },
  CarePlanActionItemUpdate: {
    action: "String",
    archived: "Boolean",
    assignmentIds: "ID",
    description: "String",
    fileResourceId: "ID",
    id: "ID",
    subtext: "String",
    title: "String"
  },
  CarePlanSelfServeCreate: {
    input: "SelfServeGoalInput",
    journeyId: "ID"
  },
  CarePlanTaskTemplateCreate: {
    body: "String",
    focusAreas: "FocusAreaEnum",
    tags: "String",
    title: "String",
    userType: "UserTypeEnum"
  },
  CarePlanTaskTemplateDestroy: {
    id: "ID"
  },
  CarePlanTaskTemplateUpdate: {
    body: "String",
    focusAreas: "FocusAreaEnum",
    id: "ID",
    tags: "String",
    title: "String",
    userType: "UserTypeEnum"
  },
  CareProfileUpdate: {
    acuity: "CareProfileAcuityEnum",
    age: "CareProfileAgeWithDeprecatedEnum",
    carePhase: "CareProfileCarePhaseEnum",
    caregiverTypes: "CareProfileCaregiverTypesInput",
    cognitiveStatuses: "CareProfileCognitiveStatusEnum",
    conservatorshipDetails: "String",
    conservatorshipStatus: "CareProfileConservatorshipStatusEnum",
    culturalConsiderations: "String",
    currentCareTransitions: "CareProfileCurrentCareTransitionsInput",
    dateOfBirth: "String",
    dateOfDeath: "String",
    dayToDaySupportNeeded: "YesNoDontKnowEnum",
    developmentalOrEducationalConcerns: "CareProfileDevelopmentalOrEducationalConcernsInput",
    durableMedicalEquipment: "DurableMedicalEquipmentEnum",
    emotionalConcerns: "CareProfileEmotionalConcernsInput",
    employmentStatus: "CareProfileEmploymentStatusEnum",
    exactAge: "Int",
    fallCount: "CareProfileFallCountEnum",
    financialManager: "CareProfileFinancialManagerInput",
    governmentBenefitsDetails: "String",
    guardianEmploymentStatus: "CareProfileEmploymentStatusEnum",
    guardianMaritalStatus: "CareProfileMaritalStatusEnum",
    guardianType: "CareProfileGuardianTypeEnum",
    guidanceType: "CareProfileGuidanceTypeEnum",
    healthInsuranceDetails: "String",
    healthInsuranceTypes: "CareProfileHealthInsuranceTypesInput",
    highRiskPregnancy: "YesNoDontKnowEnum",
    householdIncome: "String",
    householdIncomeCurrencyCode: "String",
    householdIncomeLevel: "HouseholdIncomeLevelEnum",
    id: "ID",
    immigrationStatus: "CareProfileImmigrationStatusEnum",
    incomeSources: "CareProfileIncomeSourceEnum",
    insuranceDetails: "String",
    insuranceTypes: "CareProfileInsuranceTypesInput",
    isDeceased: "Boolean",
    isVeteran: "Boolean",
    isVeteranGuardian: "Boolean",
    isVeteranPartner: "Boolean",
    languageCode: "String",
    legalHelpNeeded: "Boolean",
    legallyDisabled: "Boolean",
    lifeThreateningCondition: "YesNoDontKnowEnum",
    longTermCondition: "YesNoDontKnowEnum",
    maritalStatus: "CareProfileMaritalStatusEnum",
    medicationManagementStatus: "CareProfileMedicationManagementStatusEnum",
    medicationsAffordable: "YesNoDontKnowEnum",
    mentalHealthSupportNeeded: "YesNoDontKnowEnum",
    multipleProblems: "YesNoDontKnowEnum",
    name: "String",
    needsMetFood: "YesNoDontKnowEnum",
    needsMetHousing: "YesNoDontKnowEnum",
    needsMetTransportation: "YesNoDontKnowEnum",
    needsMetUtilities: "YesNoDontKnowEnum",
    nextConsult: "String",
    nextConsultDraft: "String",
    notes: "String",
    painSeverity: "SeverityEnum",
    pastThirtyDaysEvents: "CareProfilePastThirtyDaysEventEnum",
    pastYearHospitalizations: "Int",
    placeId: "ID",
    pregnancyDueDate: "String",
    pronouns: "PronounsEnum",
    pronounsOther: "String",
    providerFinancialConcernDetails: "String",
    providerFinancialConcerns: "CareProfileProviderFinancialConcernEnum",
    recentHospitalization: "Boolean",
    recentHospitalizationSynopsis: "String",
    recentIncident: "YesNoDontKnowEnum",
    religion: "CareProfileReligionInput",
    residenceType: "CareProfileResidenceTypeInput",
    senseOfFulfillment: "CareProfileSenseOfFulfillmentEnum",
    socialSatisfaction: "CareProfileSocialSatisfactionEnum",
    stabilitySupports: "CareProfileStabilitySupportEnum",
    stageBathingAndDressing: "CareProfileStageLimitedEnum",
    stageContinence: "CareProfileStageLimitedEnum",
    stageEating: "CareProfileStageLimitedEnum",
    stageHousekeeping: "CareProfileStageLimitedEnum",
    stageMealPrep: "CareProfileStageLimitedEnum",
    stageShopping: "CareProfileStageLimitedEnum",
    stageToileting: "CareProfileStageLimitedEnum",
    stageTransportation: "CareProfileStageLimitedEnum",
    temporaryExtraCare: "YesNoDontKnowEnum",
    typesOfSupportNeeded: "CareProfileTypesOfSupportNeededEnum",
    urgency: "CareProfileUrgencyEnum",
    useCase: "CareProfileUseCaseEnum",
    vaConnected: "Boolean"
  },
  CareTeamInviteAcceptAndRegister: {
    dateOfBirth: "String",
    email: "String",
    firstName: "String",
    lastName: "String",
    marketingAgreement: "Boolean",
    password: "String",
    placeId: "String",
    termsAndConditions: "Boolean",
    timezone: "String",
    token: "String"
  },
  CareTeamInviteAcceptExistingClient: {
    id: "ID"
  },
  CareTeamInviteCancel: {
    id: "ID"
  },
  CareTeamInviteCreate: {
    email: "String",
    journeyId: "ID",
    message: "String"
  },
  CareTeamInviteResend: {
    id: "ID"
  },
  CareTeamInvitesResend: {
    email: "String"
  },
  ChatCreate: {
    adminIds: "ID",
    clientIds: "ID",
    expertIds: "ID",
    journeyId: "ID",
    message: "String"
  },
  ChatMessagesMarkRead: {
    id: "ID"
  },
  ChatMessagesMarkUnread: {
    id: "ID"
  },
  ChatParticipantUpdate: {
    id: "ID",
    status: "ChatParticipantStatusEnum"
  },
  ClientAgreementToggle: {
    enabled: "Boolean",
    kind: "AgreementKindEnum"
  },
  ClientImpersonate: {
    id: "ID"
  },
  ClientPasswordUpdate: {
    password: "String",
    passwordCurrent: "String"
  },
  ClientRoleDestroy: {
    id: "ID"
  },
  ClientRoleUpdate: {
    careTypesProvided: "CareTypeEnum",
    caregivingConfidence: "CaregivingConfidenceEnum",
    caregivingDuration: "CaregivingDurationEnum",
    caregivingStressLevel: "CaregivingStressLevelEnum",
    distanceToLovedOne: "DistanceToLovedOneEnum",
    id: "ID",
    providerEmotionalState: "ClientRoleProviderEmotionalStateEnum",
    providerFeelsSupported: "ClientRoleProviderFeelsSupportedEnum",
    relationship: "ClientRoleRelationshipInput"
  },
  ClientSubscription: {
    id: "ID"
  },
  ClientUpdate: {
    caregivingImpactFinancial: "CaregivingImpactEnum",
    caregivingImpactPhysical: "CaregivingImpactEnum",
    caregivingImpactWork: "CaregivingImpactEnum",
    caregivingTimePerWeek: "CaregivingTimePerWeekEnum",
    culturalConsiderations: "String",
    email: "String",
    emailFrequency: "EmailFrequencyEnum",
    emailSecurity: "EmailSecurityEnum",
    everBeenCaregiver: "YesNoDontKnowEnum",
    firstName: "String",
    formattedNumber: "String",
    id: "ID",
    isVeteran: "Boolean",
    lastName: "String",
    number: "String",
    placeId: "String",
    pronouns: "PronounsEnum",
    pronounsOther: "String",
    supportingAdultChildren: "Int",
    supportingGrandparents: "Int",
    supportingMinorChildren: "Int",
    supportingOther: "Int",
    supportingParents: "Int",
    timezone: "String",
    useCasePreferences: "CareProfileUseCaseEnum",
    wantsCommunityDirectMessageEmails: "Boolean",
    wantsCommunityDirectMessagePushNotifications: "Boolean",
    wantsCommunityGroupPostEmails: "Boolean",
    wantsCommunityGroupPostPushNotifications: "Boolean",
    wantsCommunityPostCommentEmails: "Boolean",
    wantsCommunityPostCommentPushNotifications: "Boolean",
    wantsCommunityWeeklyEmails: "Boolean"
  },
  CommunityBlobCreate: {
    byteSize: "Int",
    checksum: "String",
    contentType: "String",
    filename: "String"
  },
  CommunityGroupCreate: {
    description: "String",
    groupImageBlobId: "ID",
    memberIds: "ID",
    membershipType: "CommunityGroupMembershipTypeEnum",
    name: "String",
    postingPermission: "CommunityGroupPostingPermissionEnum"
  },
  CommunityGroupUpdate: {
    description: "String",
    groupImageBlobId: "ID",
    id: "ID",
    memberIds: "ID",
    membershipType: "CommunityGroupMembershipTypeEnum",
    name: "String",
    postingPermission: "CommunityGroupPostingPermissionEnum"
  },
  CommunityNotificationUpdate: {
    id: "ID",
    isDismissed: "Boolean",
    isRead: "Boolean"
  },
  CommunityPostCreate: {
    groupId: "ID",
    parentPostId: "ID",
    postImageBlobIds: "ID",
    postVideoBlobIds: "ID",
    publishedAt: "String",
    text: "String"
  },
  CommunityPostUpdate: {
    id: "ID",
    postImageBlobIds: "ID",
    postVideoBlobIds: "ID",
    publishedAt: "String",
    text: "String"
  },
  CommunityUserCreate: {
    firstName: "String",
    lastName: "String"
  },
  CommunityUserUpdate: {
    id: "ID",
    introduced: "Boolean",
    userAvatarId: "ID"
  },
  ConsultCancel: {
    id: "ID",
    note: "String",
    reason: "ConsultCancelationReasonEnum"
  },
  ConsultCreate: {
    availability: "ConsultAvailabilityInput",
    contactMethod: "ContactMethodEnum",
    formattedNumber: "String",
    formattedSmsNumber: "String",
    invitedClientIds: "ID",
    invitedEmails: "String",
    journeyId: "ID",
    languageCode: "String",
    notes: "String",
    number: "String",
    smsNumber: "String",
    transactionalSmsSignup: "Boolean"
  },
  ConsultReassign: {
    id: "ID",
    newExpertId: "ID"
  },
  ConsultReportPublish: {
    id: "ID"
  },
  ConsultReschedule: {
    availability: "ConsultAvailabilityInput",
    contactMethod: "ContactMethodEnum",
    formattedNumber: "String",
    id: "ID",
    invitedCareTeamInviteIds: "ID",
    invitedClientIds: "ID",
    languageCode: "String",
    notes: "String",
    number: "String"
  },
  ConsultSubscription: {
    id: "ID"
  },
  ConsultUpdate: {
    contactMethod: "ContactMethodEnum",
    formattedNumber: "String",
    id: "ID",
    invitedCareTeamInviteIds: "ID",
    invitedClientIds: "ID",
    languageCode: "String",
    newJourneyId: "ID",
    notes: "String",
    number: "String",
    topics: "ConsultTopicEnum"
  },
  ContentConditionCreate: {
    careProfileField: "CareProfileActionableFieldEnum",
    careProfileValue: "CareProfileActionableFieldValueEnum",
    contentId: "ID",
    contentType: "ConditionalContentTypeEnum",
    kind: "ContentConditionKindEnum",
    providerEmotionalState: "ClientRoleProviderEmotionalStateEnum",
    providerFeelsSupported: "ClientRoleProviderFeelsSupportedEnum",
    relationship: "ClientRoleRelationshipEnum",
    stage: "StageEnum"
  },
  ContentConditionDestroy: {
    id: "ID"
  },
  CoworkerReferralCancel: {
    id: "String"
  },
  CoworkerReferralCreate: {
    email: "String",
    kind: "CoworkerReferralKindEnum",
    message: "String",
    name: "String",
    referrerId: "ID"
  },
  CreateCommunityGroupMembership: {
    groupId: "ID"
  },
  CreateCommunityPostReport: {
    postId: "ID"
  },
  CreateCommunityReaction: {
    emoji: "CommunityReactionEmojiEnum",
    postId: "ID"
  },
  DestroyCommunityGroup: {
    id: "ID"
  },
  DestroyCommunityGroupMembership: {
    id: "ID"
  },
  DestroyCommunityPost: {
    id: "ID"
  },
  DestroyCommunityReaction: {
    id: "ID"
  },
  DiagnosisCreate: {
    conditionOther: "String",
    control: "DiagnosisControlEnum",
    date: "ISO8601Date",
    journeyId: "ID",
    medicalConditionId: "ID",
    severity: "DiagnosisSeverityEnum"
  },
  DiagnosisDestroy: {
    id: "ID"
  },
  DiagnosisUpdate: {
    control: "DiagnosisControlEnum",
    date: "ISO8601Date",
    id: "ID",
    severity: "DiagnosisSeverityEnum"
  },
  DirectUploadCreate: {
    byteSize: "Int",
    checksum: "String",
    contentType: "String",
    filename: "String"
  },
  DismissCommunityPostReports: {
    postId: "ID"
  },
  EligibilityFileCreate: {
    blobId: "ID",
    employerId: "ID"
  },
  EligibilityFileExecute: {
    id: "ID"
  },
  EmailConfirm: {
    token: "String"
  },
  EmployerBenefitCreate: {
    categories: "EmployerBenefitCategoryInput",
    cost: "String",
    countryCodes: "String",
    description: "String",
    directUploadBlobIds: "ID",
    emailLabel: "String",
    emailLink: "String",
    employerId: "ID",
    hoursOfOperation: "String",
    links: "String",
    phoneNumberLabel: "String",
    phoneNumberLink: "String",
    photoBlobId: "ID",
    tags: "String",
    vendorName: "String"
  },
  EmployerBenefitDestroy: {
    id: "ID"
  },
  EmployerBenefitUpdate: {
    attachmentIds: "ID",
    categories: "EmployerBenefitCategoryInput",
    cost: "String",
    countryCodes: "String",
    description: "String",
    directUploadBlobIds: "ID",
    emailLabel: "String",
    emailLink: "String",
    hoursOfOperation: "String",
    id: "ID",
    links: "String",
    phoneNumberLabel: "String",
    phoneNumberLink: "String",
    photoBlobId: "ID",
    tags: "String",
    vendorName: "String"
  },
  EmployerContractCreate: {
    additionalPackageDetails: "String",
    billableCalculation: "BillableCalculationEnum",
    billableCap: "Int",
    employeesPurchased: "Int",
    employerId: "ID",
    hasCarePlanning: "Boolean",
    hasUnlimitedMinutes: "Boolean",
    minutes: "Int",
    packageCost: "String",
    paymentTerms: "String",
    platformFee: "String",
    startDate: "String",
    years: "Int"
  },
  EmployerContractModificationCreate: {
    additionalPackageDetails: "String",
    billableCalculation: "BillableCalculationEnum",
    billableCap: "Int",
    changesBillableCalculation: "Boolean",
    changesBillableCap: "Boolean",
    changesContractDetails: "Boolean",
    changesEmployeesPurchased: "Boolean",
    changesPackage: "Boolean",
    employeesPurchased: "Int",
    employerContractId: "ID",
    hasCarePlanning: "Boolean",
    hasUnlimitedMinutes: "Boolean",
    minutes: "Int",
    packageCost: "String",
    paymentTerms: "String",
    platformFee: "String",
    startDate: "String"
  },
  EmployerContractModificationDestroy: {
    id: "ID"
  },
  EmployerContractModificationUpdate: {
    additionalPackageDetails: "String",
    billableCalculation: "BillableCalculationEnum",
    billableCap: "Int",
    changesBillableCalculation: "Boolean",
    changesBillableCap: "Boolean",
    changesContractDetails: "Boolean",
    changesEmployeesPurchased: "Boolean",
    changesPackage: "Boolean",
    employeesPurchased: "Int",
    hasCarePlanning: "Boolean",
    hasUnlimitedMinutes: "Boolean",
    id: "ID",
    minutes: "Int",
    packageCost: "String",
    paymentTerms: "String",
    platformFee: "String"
  },
  EmployerContractUpdate: {
    additionalPackageDetails: "String",
    billableCalculation: "BillableCalculationEnum",
    billableCap: "Int",
    employeesPurchased: "Int",
    hasCarePlanning: "Boolean",
    hasUnlimitedMinutes: "Boolean",
    id: "ID",
    minutes: "Int",
    packageCost: "String",
    paymentTerms: "String",
    platformFee: "String"
  },
  EmployerCreate: {
    address: "String",
    autoJoinCommunityGroupIds: "ID",
    culture: "String",
    eligibilityMethod: "EligibilityMethodEnum",
    emailDomains: "String",
    employeeCount: "Int",
    employeeCountByCountries: "EmployeeCountByCountryInput",
    enrollmentCode: "String",
    expectedUtilization: "Int",
    fileShareFrequency: "FileShareFrequencyEnum",
    fileShareMethod: "FileShareMethodEnum",
    fileSubmissionTimeframe: "String",
    globalPopulation: "Boolean",
    hideBenefits: "Boolean",
    hideCoworkerReferrals: "Boolean",
    industry: "EmployerIndustryInput",
    name: "String",
    unpaid: "Boolean",
    website: "String"
  },
  EmployerDestroy: {
    id: "ID"
  },
  EmployerUpdate: {
    address: "String",
    autoJoinCommunityGroupIds: "ID",
    culture: "String",
    eligibilityMethod: "EligibilityMethodEnum",
    emailDomains: "String",
    employeeCount: "Int",
    employeeCountByCountries: "EmployeeCountByCountryInput",
    enrollmentCode: "String",
    expectedUtilization: "Int",
    fileShareFrequency: "FileShareFrequencyEnum",
    fileShareMethod: "FileShareMethodEnum",
    fileSubmissionTimeframe: "String",
    globalPopulation: "Boolean",
    hideBenefits: "Boolean",
    hideCoworkerReferrals: "Boolean",
    id: "ID",
    ignoreFromReporting: "Boolean",
    industry: "EmployerIndustryInput",
    name: "String",
    nonRenewed: "Boolean",
    unpaid: "Boolean",
    website: "String"
  },
  ExpertActivities: {
    expertId: "ID"
  },
  ExpertArchive: {
    id: "ID"
  },
  ExpertCreate: {
    bio: "String",
    coversAsia: "Boolean",
    coversEurope: "Boolean",
    coversUsEast: "Boolean",
    coversUsWest: "Boolean",
    email: "String",
    firstName: "String",
    lastName: "String",
    maxConsultsPerDay: "Int",
    photoBlobId: "ID",
    specialtyAges: "SpecialtyAgeEnum",
    specialtyConditions: "SpecialtyConditionEnum",
    specialtyOthers: "SpecialtyOtherEnum",
    timezone: "String",
    zoomId: "String"
  },
  ExpertImpersonate: {
    id: "ID"
  },
  ExpertRoleCreate: {
    expertId: "ID",
    journeyId: "ID",
    role: "ExpertRoleRoleEnum"
  },
  ExpertRoleDestroy: {
    id: "ID"
  },
  ExpertTasks: {
    expertId: "ID"
  },
  ExpertTimeLogs: {
    expertId: "ID"
  },
  ExpertUpdate: {
    allowsSameDayConsults: "Boolean",
    bio: "String",
    consultNoticeBuffer: "Int",
    coversAsia: "Boolean",
    coversEurope: "Boolean",
    coversUsEast: "Boolean",
    coversUsWest: "Boolean",
    firstName: "String",
    id: "ID",
    journeyAssignmentWeight: "Float",
    lastName: "String",
    maxConsultsPerDay: "Int",
    photoBlobId: "ID",
    postConsultBuffer: "Int",
    preConsultBuffer: "Int",
    secondPhotoBlobId: "ID",
    specialtyAges: "SpecialtyAgeEnum",
    specialtyConditions: "SpecialtyConditionEnum",
    specialtyOthers: "SpecialtyOtherEnum",
    timezone: "String",
    zoomId: "String"
  },
  FileResourceCreate: {
    blobId: "ID",
    folderId: "ID",
    journeyId: "ID",
    title: "String",
    worksheetId: "ID"
  },
  FileResourceDestroy: {
    id: "ID"
  },
  FileResourceUpdate: {
    blobId: "ID",
    folderId: "ID",
    id: "ID",
    title: "String"
  },
  FolderCreate: {
    journeyId: "ID",
    title: "String"
  },
  FolderDestroy: {
    id: "ID"
  },
  FolderUpdate: {
    id: "ID",
    title: "String"
  },
  GetActivities: {
    activityKinds: "ActivityKindEnum",
    actorKind: "ActivityActorKindEnum",
    clientId: "ID",
    cursor: "String",
    expertId: "ID",
    section: "ActivitySectionEnum",
    startTime: "ISO8601DateTime"
  },
  GetActivitySummary: {
    clientId: "ID",
    expertId: "ID",
    startTime: "ISO8601DateTime"
  },
  GetAdmin: {
    id: "ID"
  },
  GetAdmins: {
    cursor: "String"
  },
  GetArticle: {
    articleShareId: "ID",
    id: "ID"
  },
  GetArticleBasics: {
    contentTypes: "ArticleContentTypeEnum",
    count: "Int",
    cursor: "String",
    search: "String",
    sortBy: "ArticleSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetArticles: {
    contentConditionKinds: "ContentConditionKindEnum",
    contentTypes: "ArticleContentTypeEnum",
    count: "Int",
    cursor: "String",
    focusArea: "FocusAreaEnum",
    hostedByGather: "Boolean",
    includeHidden: "Boolean",
    recurringWebinarInclusion: "RecurringWebinarInclusionEnum",
    search: "String",
    sortBy: "ArticleSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetArticlesBasic: {
    completedWebinars: "Boolean",
    contentConditionKinds: "ContentConditionKindEnum",
    contentTypes: "ArticleContentTypeEnum",
    count: "Int",
    cursor: "String",
    focusArea: "FocusAreaEnum",
    hostedByGather: "Boolean",
    imminentWebinars: "Boolean",
    recurringWebinarInclusion: "RecurringWebinarInclusionEnum",
    search: "String",
    sortBy: "ArticleSortByEnum",
    sortDirection: "SortDirectionEnum",
    upcomingWebinars: "Boolean",
    useCase: "CareProfileUseCaseEnum"
  },
  GetArticlesCsvDownload: {
    contentTypes: "ArticleContentTypeEnum"
  },
  GetArticlesSearch: {
    contentTypes: "ArticleContentTypeEnum",
    count: "Int",
    focusArea: "FocusAreaEnum",
    search: "String"
  },
  GetArticlesSummary: {
    contentConditionKinds: "ContentConditionKindEnum",
    contentTypes: "ArticleContentTypeEnum",
    focusArea: "FocusAreaEnum",
    includeHidden: "Boolean",
    recurringWebinarInclusion: "RecurringWebinarInclusionEnum",
    search: "String"
  },
  GetArticlesWithTitle: {
    contentConditionKinds: "ContentConditionKindEnum",
    contentTypes: "ArticleContentTypeEnum",
    count: "Int",
    cursor: "String",
    focusArea: "FocusAreaEnum",
    search: "String",
    sortBy: "ArticleSortByEnum",
    sortDirection: "SortDirectionEnum",
    upcomingWebinars: "Boolean"
  },
  GetAttachment: {
    id: "ID"
  },
  GetAttachmentDownload: {
    id: "ID"
  },
  GetAutomation: {
    id: "ID"
  },
  GetAutomationApplication: {
    id: "ID"
  },
  GetAutomationApplications: {
    automationId: "ID",
    cursor: "String",
    journeyId: "ID"
  },
  GetAutomations: {
    cursor: "String",
    searchTerm: "String",
    sortBy: "AutomationSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetCarePlanTaskTemplate: {
    id: "ID"
  },
  GetCarePlanTaskTemplates: {
    cursor: "String",
    focusArea: "FocusAreaEnum",
    searchTerm: "String",
    sortBy: "CarePlanTaskTemplateSortByEnum",
    sortDirection: "SortDirectionEnum",
    userType: "UserTypeEnum"
  },
  GetCarePlanTaskTemplatesBasic: {
    count: "Int",
    cursor: "String",
    focusArea: "FocusAreaEnum",
    searchTerm: "String",
    sortBy: "CarePlanTaskTemplateSortByEnum",
    sortDirection: "SortDirectionEnum",
    userType: "UserTypeEnum"
  },
  GetCarePlanTaskTemplatesSearch: {
    count: "Int",
    focusArea: "FocusAreaEnum",
    search: "String",
    userType: "UserTypeEnum"
  },
  GetCareProfileChanges: {
    count: "Int",
    cursor: "String",
    endDate: "ISO8601Date",
    journeyId: "ID",
    startDate: "ISO8601Date"
  },
  GetCareTeamInvite: {
    token: "String"
  },
  GetChatMessages: {
    cursor: "String",
    id: "ID"
  },
  GetClientForAdmin: {
    id: "ID"
  },
  GetClientForClientRouter: {
    id: "ID"
  },
  GetClientWithAccount: {
    id: "ID"
  },
  GetClientWithAgreements: {
    id: "ID"
  },
  GetClientWithBanners: {
    id: "ID"
  },
  GetClientWithJourneys: {
    id: "ID"
  },
  GetClientWithLocation: {
    id: "ID"
  },
  GetClientWithNeedsWorkEmailVerification: {
    id: "ID"
  },
  GetClientWithReadyForReview: {
    id: "ID"
  },
  GetClientWithRoles: {
    id: "ID"
  },
  GetClientWithTimeGrants: {
    id: "ID"
  },
  GetCommunityBlob: {
    id: "ID"
  },
  GetCommunityGroup: {
    id: "ID"
  },
  GetCommunityGroupMembers: {
    count: "Int",
    cursor: "String",
    groupId: "ID",
    search: "String"
  },
  GetCommunityGroups: {
    count: "Int",
    cursor: "String",
    ids: "ID",
    membershipTypes: "CommunityGroupMembershipTypeEnum",
    mine: "Boolean",
    search: "String",
    sortBy: "CommunityGroupsSortByEnum",
    sortDirection: "CommunitySortDirectionEnum"
  },
  GetCommunityMyNotificationsCount: {
    isRead: "Boolean"
  },
  GetCommunityNotification: {
    id: "ID"
  },
  GetCommunityNotifications: {
    count: "Int",
    cursor: "String",
    isRead: "Boolean"
  },
  GetCommunityPost: {
    id: "ID"
  },
  GetCommunityPosts: {
    authorId: "ID",
    comments: "Boolean",
    count: "Int",
    cursor: "String",
    groupId: "ID",
    myGroups: "Boolean",
    reported: "Boolean",
    sortBy: "CommunityPostsSortByEnum",
    sortDirection: "CommunitySortDirectionEnum"
  },
  GetCommunityPostsWithPostReports: {
    authorId: "ID",
    comments: "Boolean",
    count: "Int",
    cursor: "String",
    groupId: "ID",
    myGroups: "Boolean",
    reported: "Boolean",
    sortBy: "CommunityPostsSortByEnum",
    sortDirection: "CommunitySortDirectionEnum"
  },
  GetCommunityReactions: {
    count: "Int",
    cursor: "String",
    sortBy: "CommunityReactionsSortByEnum",
    sortDirection: "CommunitySortDirectionEnum"
  },
  GetCommunityUser: {
    id: "ID"
  },
  GetCommunityUsers: {
    count: "Int",
    cursor: "String",
    grayceUserIds: "ID",
    grayceUserTypes: "CommunityGrayceUserTypeEnum",
    search: "String",
    sortBy: "CommunityUsersSortByEnum",
    sortDirection: "CommunitySortDirectionEnum"
  },
  GetCommunityUsersAsClient: {
    count: "Int",
    cursor: "String",
    grayceUserIds: "ID",
    grayceUserTypes: "CommunityGrayceUserTypeEnum",
    search: "String",
    sortBy: "CommunityUsersSortByEnum",
    sortDirection: "CommunitySortDirectionEnum"
  },
  GetConsult: {
    id: "ID"
  },
  GetConsultAvailabilityDays: {
    duration: "ConsultDurationEnum",
    endTime: "ISO8601DateTime",
    journeyId: "ID",
    nonPrimaryExperts: "Boolean",
    reschedulingConsultId: "ID",
    startTime: "ISO8601DateTime"
  },
  GetContentConditions: {
    focusArea: "FocusAreaEnum",
    kinds: "ContentConditionKindEnum",
    search: "String"
  },
  GetDrugsSearch: {
    search: "String"
  },
  GetEligibilityFile: {
    id: "ID"
  },
  GetEligibilityFileNewHiresDownload: {
    eligibilityFileIds: "String"
  },
  GetEligibilityFiles: {
    cursor: "String",
    employerId: "ID"
  },
  GetEmailMessage: {
    id: "ID"
  },
  GetEmailMessages: {
    cursor: "String"
  },
  GetEmployees: {
    count: "Int",
    cursor: "String",
    employerId: "ID",
    search: "String",
    sortBy: "EmployeeSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetEmployer: {
    id: "ID"
  },
  GetEmployerBenefit: {
    id: "ID"
  },
  GetEmployerBenefitViewStats: {
    employerId: "ID",
    endDate: "String",
    startDate: "String"
  },
  GetEmployerBenefits: {
    category: "String",
    count: "Int",
    cursor: "String",
    journeyId: "ID",
    search: "String"
  },
  GetEmployerBenefitsSearch: {
    category: "String",
    count: "Int",
    journeyId: "ID",
    search: "String"
  },
  GetEmployerContract: {
    id: "ID"
  },
  GetEmployerQbr: {
    endDate: "ISO8601Date",
    id: "ID",
    startDate: "ISO8601Date"
  },
  GetEmployerWithAppEvents: {
    id: "ID"
  },
  GetEmployerWithBenefits: {
    id: "ID"
  },
  GetEmployerWithContracts: {
    id: "ID"
  },
  GetEmployers: {
    count: "Int",
    cursor: "String",
    searchTerm: "String",
    sortBy: "EmployerSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetExpert: {
    id: "ID"
  },
  GetExpertAiChatThreads: {
    cursor: "String"
  },
  GetExpertCarePlanTurnAroundTimes: {
    cursor: "String",
    endDate: "String",
    expertId: "ID",
    sortBy: "CarePlanTurnAroundTimeSortByEnum",
    sortDirection: "SortDirectionEnum",
    startDate: "String"
  },
  GetExpertCarePlans: {
    cursor: "String",
    expertArchived: "Boolean",
    expertId: "ID",
    icrStatus: "ConsultReportStatusEnum",
    sortBy: "CarePlanSortByEnum",
    sortDirection: "SortDirectionEnum",
    status: "CarePlanStatusEnum"
  },
  GetExpertClients: {
    cursor: "String",
    endDate: "String",
    expertId: "ID",
    primaryJourneys: "Boolean",
    searchTerm: "String",
    sortBy: "ExpertClientsSortByEnum",
    sortDirection: "SortDirectionEnum",
    startDate: "String"
  },
  GetExpertConsults: {
    consultReportStatus: "ConsultReportStatusEnum",
    cursor: "String",
    duration: "ConsultDurationEnum",
    employerId: "ID",
    endDate: "String",
    expertId: "ID",
    forExpert: "Boolean",
    needsInterpretation: "Boolean",
    selfPaid: "Boolean",
    sortBy: "ExpertConsultsSortByEnum",
    sortDirection: "SortDirectionEnum",
    startDate: "String",
    timePeriod: "ConsultTimePeriodEnum"
  },
  GetExpertConsultsByAttribute: {
    consultReportStatus: "ConsultReportStatusEnum",
    cursor: "String",
    duration: "ConsultDurationEnum",
    employerId: "ID",
    endDate: "String",
    expertId: "ID",
    forExpert: "Boolean",
    needsInterpretation: "Boolean",
    position: "ConsultPositionEnum",
    selfPaid: "Boolean",
    sortBy: "ExpertConsultsSortByEnum",
    sortDirection: "SortDirectionEnum",
    startDate: "String",
    timePeriod: "ConsultTimePeriodEnum"
  },
  GetExpertJourneySummaries: {
    expertId: "ID",
    journeyIds: "ID"
  },
  GetExpertJourneys: {
    allJourneys: "Boolean",
    cursor: "String",
    employerId: "ID",
    expertArchived: "Boolean",
    expertId: "ID",
    expertUnopened: "Boolean",
    primaryExpertId: "ID",
    role: "ExpertJourneysExpertRolesEnum",
    search: "String",
    selfPaid: "Boolean",
    sortBy: "ExpertJourneysSortByEnum",
    sortDirection: "SortDirectionEnum",
    statuses: "ExpertJourneysStatusEnum"
  },
  GetExpertJourneysBasic: {
    cursor: "String",
    expertId: "ID",
    search: "String",
    sortBy: "ExpertJourneysSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetExpertNeedsResponseChats: {
    cursor: "String",
    expertId: "ID"
  },
  GetExpertNeedsResponseMessageCount: {
    expertId: "ID"
  },
  GetExpertOverview: {
    endDate: "String",
    expertId: "ID",
    startDate: "String"
  },
  GetExpertOverviews: {
    cursor: "String",
    endDate: "String",
    expertId: "ID",
    sortBy: "ExpertOverviewsSortByEnum",
    sortDirection: "SortDirectionEnum",
    startDate: "String"
  },
  GetExpertRole: {
    expertId: "ID",
    journeyId: "ID"
  },
  GetExpertSummary: {
    expertId: "ID"
  },
  GetExpertTasks: {
    archived: "Boolean",
    assignee: "TaskAssigneeEnum",
    clientId: "ID",
    completed: "Boolean",
    cursor: "String",
    endTime: "String",
    expertId: "ID",
    forTime: "String",
    onlyGoalTasks: "Boolean",
    sortBy: "ExpertTasksSortByEnum",
    sortDirection: "SortDirectionEnum",
    startTime: "String",
    status: "TaskStatusEnum"
  },
  GetExperts: {
    cursor: "String",
    searchTerm: "String",
    sortBy: "ExpertSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetExpertsBasic: {
    cursor: "String",
    searchTerm: "String",
    sortBy: "ExpertSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetExpertsWithOutOfOfficeSummary: {
    cursor: "String"
  },
  GetGoalCategories: {
    excludeExistingGoals: "Boolean",
    excludeMissingGoalTemplate: "Boolean",
    excludeOther: "Boolean",
    journeyId: "ID"
  },
  GetGoalTemplate: {
    id: "ID"
  },
  GetGoalTemplates: {
    archived: "Boolean",
    category: "GoalCategoryEnum",
    countryCode: "String",
    cursor: "String",
    focusArea: "FocusAreaEnum",
    searchTerm: "String",
    sortBy: "GoalTemplateSortByEnum",
    sortDirection: "SortDirectionEnum",
    subcategory: "GoalSubcategoryEnum",
    useCase: "CareProfileUseCaseEnum"
  },
  GetGoalTemplatesBasic: {
    archived: "Boolean",
    cursor: "String",
    focusArea: "FocusAreaEnum",
    searchTerm: "String",
    sortBy: "GoalTemplateSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetHotlines: {
    count: "Int",
    cursor: "String",
    search: "String"
  },
  GetInternalNoteTemplate: {
    id: "ID"
  },
  GetInternalNoteTemplates: {
    cursor: "String",
    searchTerm: "String",
    sortBy: "InternalNoteTemplatesSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetJourneyBasic: {
    id: "ID"
  },
  GetJourneyForMessages: {
    id: "ID"
  },
  GetJourneyForTasks: {
    id: "ID"
  },
  GetJourneyForTimeLogging: {
    id: "ID"
  },
  GetJourneyMessages: {
    cursor: "String",
    id: "ID",
    search: "String"
  },
  GetJourneyTasks: {
    assignee: "TaskAssigneeEnum",
    cursor: "String",
    journeyId: "ID",
    status: "TaskStatusEnum"
  },
  GetJourneyWithBanners: {
    id: "ID"
  },
  GetJourneyWithCareProfile: {
    id: "ID"
  },
  GetJourneyWithCareProfileAndEligibility: {
    id: "ID"
  },
  GetJourneyWithClientRoles: {
    id: "ID"
  },
  GetJourneyWithClientVisibleTasksBasic: {
    id: "ID"
  },
  GetJourneyWithClients: {
    id: "ID"
  },
  GetJourneyWithConsults: {
    id: "ID"
  },
  GetJourneyWithDocuments: {
    id: "ID"
  },
  GetJourneyWithEmployerBenefitCategories: {
    id: "ID"
  },
  GetJourneyWithExpert: {
    id: "ID"
  },
  GetJourneyWithExpertAndConsults: {
    id: "ID"
  },
  GetJourneyWithExpertRoles: {
    id: "ID"
  },
  GetJourneyWithExplore: {
    id: "ID"
  },
  GetJourneyWithLovedOne: {
    id: "ID"
  },
  GetJourneyWithOnboarding: {
    id: "ID"
  },
  GetJourneyWithOnboardingInfo: {
    id: "ID"
  },
  GetJourneyWithOwnerClient: {
    id: "ID"
  },
  GetJourneyWithPlan: {
    id: "ID"
  },
  GetJourneyWithSignedDocuments: {
    id: "ID"
  },
  GetJourneyWithTimeLogs: {
    id: "ID"
  },
  GetJourneys: {
    category: "JourneyCategoryEnum",
    consultComplete: "Boolean",
    cursor: "String",
    employerId: "ID",
    expertId: "ID",
    search: "String",
    selfPaid: "Boolean",
    skipIncompleteSecondary: "Boolean",
    skipUnassigned: "Boolean",
    sortBy: "JourneySortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetJourneysSearch: {
    count: "Int",
    dataType: "JourneysSearchDataTypeEnum",
    dateRangeEnd: "ISO8601Date",
    dateRangeStart: "ISO8601Date",
    dateRangeType: "JourneysSearchDateRangeTypeEnum",
    employerId: "ID",
    onlyMyJourneys: "Boolean",
    search: "String",
    selfPaid: "Boolean",
    sortBy: "JourneysSearchSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetMedicalConditionsSearch: {
    search: "String"
  },
  GetMessage: {
    id: "ID"
  },
  GetMessageTemplateCategories: {
    cursor: "String",
    search: "String",
    sortBy: "MessageTemplateCategoriesSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetMessageTemplates: {
    categoryId: "ID",
    cursor: "String",
    search: "String",
    sortBy: "MessageTemplatesSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetMessages: {
    cursor: "String"
  },
  GetMyCalendarEvents: {
    count: "Int",
    cursor: "String",
    endTime: "String",
    startTime: "String"
  },
  GetMyChats: {
    count: "Int",
    cursor: "String",
    journeyId: "ID"
  },
  GetMyMessagesSearch: {
    count: "Int",
    cursor: "String",
    journeyId: "ID",
    search: "String"
  },
  GetMyUnreadMessageCounts: {
    journeyId: "ID"
  },
  GetOidcProvider: {
    id: "ID"
  },
  GetOidcProviders: {
    cursor: "String"
  },
  GetOutOfOfficePeriod: {
    id: "ID"
  },
  GetOutOfOfficePeriodConflicts: {
    endTime: "String",
    expertId: "ID",
    startTime: "String"
  },
  GetOutOfOfficePeriods: {
    cursor: "String",
    endDate: "String",
    startDate: "String"
  },
  GetPackageGrant: {
    id: "ID"
  },
  GetPackageGrants: {
    cursor: "String",
    eligibilityStatus: "PackageGrantEligibilityStatusEnum",
    employerId: "ID",
    selfPaid: "Boolean",
    sortBy: "PackageGrantSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetPdfDownload: {
    id: "ID",
    journeyId: "ID",
    type: "PdfTypeEnum"
  },
  GetPeerSupportRequests: {
    cursor: "String"
  },
  GetPins: {
    hideEditModePins: "Boolean",
    journeyId: "ID",
    resourceTypes: "PinResourceTypeEnum"
  },
  GetRecommendations: {
    journeyId: "ID",
    resourceType: "RecommendationResourceTypeEnum"
  },
  GetRecommendedArticles: {
    contentTypes: "ArticleContentTypeEnum",
    count: "Int",
    journeyId: "ID",
    prioritizeExpertRecommended: "Boolean",
    prioritizeGoalRecommended: "Boolean"
  },
  GetResourceFeatureTemplate: {
    id: "ID"
  },
  GetResourceFeatureTemplates: {
    cursor: "String",
    search: "String"
  },
  GetSignedDocument: {
    id: "ID"
  },
  GetSignedDocumentFileResource: {
    id: "ID"
  },
  GetSurveyResponses: {
    cursor: "String",
    employerId: "ID",
    endDate: "String",
    expertId: "ID",
    service: "SurveyResponseServiceEnum",
    startDate: "String"
  },
  GetTags: {
    searchTerm: "String",
    tagType: "TagTypeEnum"
  },
  GetTaskAuditLogs: {
    id: "ID"
  },
  GetTaskSummary: {
    expertId: "ID"
  },
  GetTimeLogs: {
    billable: "TimeLogBillableStatusEnum",
    cursor: "String",
    expertId: "ID",
    journeyId: "ID"
  },
  GetTimeLogsByGroup: {
    billable: "TimeLogBillableStatusEnum",
    cursor: "String",
    endDate: "String",
    expertId: "ID",
    journeyId: "ID",
    startDate: "String"
  },
  GetTimesheets: {
    month: "Int",
    year: "Int"
  },
  GetWebinarRegistrations: {
    cursor: "String",
    sortBy: "WebinarRegistrationSortByEnum",
    sortDirection: "SortDirectionEnum",
    webinarId: "ID"
  },
  GetWorksheet: {
    id: "ID"
  },
  GetWorksheetTemplate: {
    id: "ID"
  },
  GetWorksheetTemplates: {
    archived: "Boolean",
    cursor: "String",
    focusArea: "FocusAreaEnum",
    searchTerm: "String",
    sortBy: "WorksheetTemplateSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetWorksheetTemplatesBasic: {
    archived: "Boolean",
    count: "Int",
    cursor: "String",
    focusArea: "FocusAreaEnum",
    searchTerm: "String",
    sortBy: "WorksheetTemplateSortByEnum",
    sortDirection: "SortDirectionEnum"
  },
  GetWorksheetTemplatesSearch: {
    count: "Int",
    focusArea: "FocusAreaEnum",
    search: "String"
  },
  GoalCreate: {
    category: "GoalCategoryEnum",
    draft: "Boolean",
    goalTemplateId: "ID",
    journeyId: "ID",
    subcategory: "GoalSubcategoryEnum",
    subcategoryOther: "String",
    title: "String"
  },
  GoalDestroy: {
    id: "ID"
  },
  GoalItemCreate: {
    articleId: "ID",
    employerBenefitId: "ID",
    goalId: "ID",
    itemType: "GoalItemTypeEnum",
    tool: "ToolEnum"
  },
  GoalItemRemove: {
    id: "ID"
  },
  GoalItemToggleCompleted: {
    completed: "Boolean",
    id: "ID"
  },
  GoalMarkRead: {
    acknowledgeClosed: "Boolean",
    id: "ID"
  },
  GoalPublish: {
    id: "ID"
  },
  GoalTemplateCreate: {
    articleIds: "ID",
    benefitCategories: "EmployerBenefitCategoryEnum",
    category: "GoalCategoryEnum",
    countryCodes: "String",
    helpText: "String",
    subcategory: "GoalSubcategoryEnum",
    tools: "ToolEnum",
    useCases: "CareProfileUseCaseEnum",
    worksheetTemplateIds: "ID"
  },
  GoalTemplateUpdate: {
    archived: "Boolean",
    articleIds: "ID",
    benefitCategories: "EmployerBenefitCategoryEnum",
    category: "GoalCategoryEnum",
    countryCodes: "String",
    helpText: "String",
    id: "ID",
    subcategory: "GoalSubcategoryEnum",
    tools: "ToolEnum",
    useCases: "CareProfileUseCaseEnum",
    worksheetTemplateIds: "ID"
  },
  GoalToEditMode: {
    id: "ID"
  },
  GoalUpdate: {
    archived: "Boolean",
    category: "GoalCategoryEnum",
    completed: "Boolean",
    id: "ID",
    subcategory: "GoalSubcategoryEnum",
    subcategoryOther: "String",
    title: "String"
  },
  GoogleAuthenticationCreate: {
    accessType: "ClientAccessTypeEnum",
    clientType: "GoogleClientTypeEnum",
    code: "String",
    redirectUri: "String",
    scope: "String"
  },
  GoogleAuthenticationProcess: {
    accessType: "ClientAccessTypeEnum",
    clientType: "GoogleClientTypeEnum",
    idToken: "String",
    refreshToken: "String",
    scope: "String"
  },
  InitialConsultReportPublish: {
    consultId: "ID",
    id: "ID"
  },
  InternalNoteCreate: {
    body: "String",
    consultReportId: "ID",
    internalNoteTemplateId: "ID",
    journeyId: "ID",
    kind: "InternalNoteKindEnum",
    title: "String"
  },
  InternalNoteDestroy: {
    id: "ID"
  },
  InternalNoteTemplateCreate: {
    content: "String",
    title: "String"
  },
  InternalNoteTemplateDestroy: {
    id: "ID"
  },
  InternalNoteTemplateUpdate: {
    content: "String",
    id: "ID",
    title: "String"
  },
  InternalNoteUpdate: {
    body: "String",
    id: "ID",
    title: "String"
  },
  InternalNotes: {
    journeyId: "ID"
  },
  IssueReportCreate: {
    directUploadBlobIds: "String",
    journeyId: "ID",
    message: "String"
  },
  JourneyCarePlanPublish: {
    id: "ID"
  },
  JourneyCreate: {
    careProfilePlaceId: "ID",
    clientId: "ID",
    clientPlaceId: "ID",
    name: "String"
  },
  JourneyDestroy: {
    id: "ID"
  },
  JourneyExpertArchivedToggle: {
    expertArchived: "Boolean",
    expertArchivedReason: "JourneyExpertArchivedReasonInput",
    journeyId: "ID"
  },
  JourneyMarkRead: {
    id: "ID"
  },
  JourneyOnboard: {
    coworkerReferralId: "ID",
    coworkerReferralToken: "String",
    id: "ID"
  },
  JourneyOpen: {
    id: "ID"
  },
  JourneySignedDocuments: {
    journeyId: "ID"
  },
  JourneySubscription: {
    id: "ID"
  },
  JourneyTasks: {
    journeyId: "ID"
  },
  JourneyUpdate: {
    id: "ID",
    stage: "StageEnum"
  },
  LegalDocumentCreate: {
    directUploadBlobIds: "String",
    journeyId: "ID",
    kind: "LegalDocumentKindEnum",
    notes: "String",
    status: "LegalDocumentStatusEnum",
    storageLocation: "String",
    updateDate: "String"
  },
  LegalDocumentDestroy: {
    id: "ID"
  },
  LegalDocumentUpdate: {
    directUploadBlobIds: "ID",
    fileResourceIds: "ID",
    id: "ID",
    notes: "String",
    status: "LegalDocumentStatusEnum",
    storageLocation: "String",
    updateDate: "String"
  },
  LinkEventCreate: {
    journeyId: "ID",
    link: "String",
    resourceId: "ID",
    resourceType: "String"
  },
  MedicationCreate: {
    dosage: "String",
    drugId: "ID",
    format: "MedicationFormatInput",
    instructions: "String",
    journeyId: "ID",
    kind: "MedicationKindEnum",
    name: "String",
    notes: "String",
    paymentType: "MedicationPaymentTypeEnum",
    pharmacy: "String",
    prescriber: "String",
    prescriptionNumber: "String",
    reasonForTaking: "String",
    refillFrequency: "String"
  },
  MedicationDestroy: {
    id: "ID"
  },
  MedicationUpdate: {
    dosage: "String",
    drugId: "ID",
    format: "MedicationFormatInput",
    id: "ID",
    instructions: "String",
    kind: "MedicationKindEnum",
    name: "String",
    notes: "String",
    paymentType: "MedicationPaymentTypeEnum",
    pharmacy: "String",
    prescriber: "String",
    prescriptionNumber: "String",
    reasonForTaking: "String",
    refillFrequency: "String"
  },
  MessageCreate: {
    body: "String",
    chatId: "ID",
    cta: "MessageCtaEnum",
    directUploadBlobIds: "String",
    fromGrayce: "Boolean",
    messageTemplateId: "ID",
    recordId: "ID",
    recordType: "MessageRecordTypeEnum"
  },
  MessageTemplateCategoryCreate: {
    title: "String"
  },
  MessageTemplateCategoryDestroy: {
    id: "ID"
  },
  MessageTemplateCategoryUpdate: {
    id: "ID",
    title: "String"
  },
  MessageTemplateCreate: {
    content: "String",
    messageTemplateCategoryId: "ID",
    title: "String"
  },
  MessageTemplateDestroy: {
    id: "ID"
  },
  MessageTemplateUpdate: {
    content: "String",
    id: "ID",
    messageTemplateCategoryId: "ID",
    title: "String"
  },
  MessageTyping: {
    chatId: "ID"
  },
  MessageUpdate: {
    id: "ID",
    responseNeeded: "Boolean"
  },
  MessageUserTyping: {
    chatId: "ID"
  },
  NotificationMarkRead: {
    id: "ID"
  },
  OidcAuthenticationComplete: {
    code: "String",
    nonce: "String",
    redirectUri: "String",
    state: "String"
  },
  OidcAuthenticationCreate: {
    nonce: "String",
    oidcProviderName: "String",
    redirectUri: "String"
  },
  OidcEndSessionCreate: {
    redirectUri: "String"
  },
  OidcProviderCreate: {
    employerId: "ID",
    host: "String",
    identifier: "String",
    name: "String",
    secret: "String"
  },
  OidcProviderDestroy: {
    id: "ID"
  },
  OidcProviderUpdate: {
    employerId: "ID",
    host: "String",
    id: "ID",
    identifier: "String",
    name: "String",
    secret: "String"
  },
  OutOfOfficePeriodCreate: {
    backupExpertId: "ID",
    endTime: "String",
    expertId: "ID",
    startTime: "String"
  },
  OutOfOfficePeriodDestroy: {
    id: "ID"
  },
  OutOfOfficePeriodUpdate: {
    backupExpertId: "ID",
    endTime: "String",
    id: "ID",
    startTime: "String"
  },
  PackageGrantCreate: {
    clientId: "ID",
    minutes: "Int"
  },
  PackageGrantEligibilityDecisionCreate: {
    decision: "PackageGrantEligibilityDecisionEnum",
    employeeId: "ID",
    employerId: "ID",
    notes: "String",
    packageGrantId: "ID"
  },
  PasswordReset: {
    email: "String"
  },
  PasswordUpdate: {
    password: "String",
    token: "String"
  },
  PaymentIntentCreate: {
    packagePurchaseOptionId: "ID"
  },
  PinToggle: {
    goalId: "ID",
    journeyId: "ID",
    pinned: "Boolean",
    resourceId: "ID",
    resourceType: "PinResourceTypeEnum",
    tool: "ToolEnum"
  },
  ProviderCreate: {
    address: "String",
    fax: "String",
    journeyId: "ID",
    kind: "ProviderKindEnum",
    name: "String",
    notes: "String",
    patientPortal: "String",
    phone: "String",
    practiceName: "String",
    specialty: "String",
    website: "String"
  },
  ProviderDestroy: {
    id: "ID"
  },
  ProviderUpdate: {
    address: "String",
    fax: "String",
    id: "ID",
    kind: "ProviderKindEnum",
    name: "String",
    notes: "String",
    patientPortal: "String",
    phone: "String",
    practiceName: "String",
    specialty: "String",
    website: "String"
  },
  PurchaseCreate: {
    packagePurchaseOptionId: "ID",
    paymentId: "ID"
  },
  PushNotificationTokenCreate: {
    platform: "PushNotificationPlatformEnum",
    token: "String"
  },
  RecommendedTagsGenerate: {
    aiContentFields: "AiContentFieldInput",
    tagTypes: "TagTypeEnum"
  },
  RefreshPeriodUpdate: {
    id: "ID",
    targetPackageCount: "Int"
  },
  RegistrationComplete: {
    dateOfBirth: "String",
    email: "String",
    employer: "String",
    enrollmentCode: "String",
    firstName: "String",
    lastName: "String",
    marketingAgreement: "Boolean",
    termsAndConditions: "Boolean",
    timezone: "String",
    workEmail: "String"
  },
  RegistrationStart: {
    accessType: "ClientAccessTypeEnum",
    email: "String",
    invalidateOnExistingInvites: "Boolean",
    password: "String",
    topicOfInterest: "ClientTopicOfInterestEnum",
    utmCampaign: "String",
    utmContent: "String",
    utmMedium: "String",
    utmSource: "String"
  },
  RescheduleConsultEmailSend: {
    id: "ID"
  },
  ResourceCreate: {
    cost: "String",
    description: "String",
    featureValues: "ResourceFeatureValueInput",
    hotlineId: "ID",
    linkTitle: "String",
    linkUrl: "String",
    phoneNumber: "String",
    taskId: "ID",
    title: "String"
  },
  ResourceDestroy: {
    id: "ID"
  },
  ResourceFeatureTemplateCreate: {
    resourceFeatures: "ResourceFeatureInput",
    title: "String"
  },
  ResourceFeatureTemplateDestroy: {
    id: "ID"
  },
  ResourceFeatureTemplateUpdate: {
    id: "ID",
    resourceFeatures: "ResourceFeatureInput",
    title: "String"
  },
  ResourceUpdate: {
    cost: "String",
    description: "String",
    featureValues: "ResourceFeatureValueInput",
    hotlineId: "ID",
    id: "ID",
    linkTitle: "String",
    linkUrl: "String",
    notes: "String",
    phoneNumber: "String",
    title: "String",
    useful: "Boolean"
  },
  ResourcesReorder: {
    resourceIds: "ID",
    taskId: "ID"
  },
  SessionCreate: {
    email: "String",
    password: "String",
    recaptchaToken: "String"
  },
  SignedDocumentCreate: {
    attachmentBlobId: "String",
    carePlanActionItemId: "ID",
    documentFor: "String",
    journeyId: "ID",
    kind: "SignedDocumentKindEnum",
    medium: "SignedDocumentMediumEnum",
    recipientEmailAddress: "String",
    recipientName: "String"
  },
  SignedDocumentFax: {
    documentFor: "String",
    faxFor: "String",
    faxNumber: "String",
    id: "ID",
    note: "String"
  },
  SignedDocumentRevoke: {
    id: "ID"
  },
  SnoozeNextConsultReminder: {
    journeyId: "ID"
  },
  SupergraphUserUpdate: {
    caregivingImpactFinancial: "CaregivingImpactEnum",
    caregivingImpactPhysical: "CaregivingImpactEnum",
    caregivingImpactWork: "CaregivingImpactEnum",
    caregivingTimePerWeek: "CaregivingTimePerWeekEnum",
    communityId: "ID",
    culturalConsiderations: "String",
    displayName: "String",
    email: "String",
    emailFrequency: "EmailFrequencyEnum",
    emailSecurity: "EmailSecurityEnum",
    everBeenCaregiver: "YesNoDontKnowEnum",
    firstName: "String",
    formattedNumber: "String",
    isVeteran: "Boolean",
    lastName: "String",
    monolithId: "ID",
    number: "String",
    placeId: "String",
    pronouns: "PronounsEnum",
    pronounsOther: "String",
    supportingAdultChildren: "Int",
    supportingGrandparents: "Int",
    supportingMinorChildren: "Int",
    supportingOther: "Int",
    supportingParents: "Int",
    timezone: "String",
    useCasePreferences: "CareProfileUseCaseEnum"
  },
  SurveyResponseCreate: {
    clientId: "ID",
    score: "Int"
  },
  TaskCancel: {
    canceled: "Boolean",
    id: "ID",
    note: "String"
  },
  TaskCreate: {
    action: "String",
    carePlanTaskTemplateId: "ID",
    category: "TaskCategoryInput",
    clientVisible: "Boolean",
    directUploadBlobIds: "ID",
    dueTime: "String",
    expectedStartDate: "String",
    expertId: "ID",
    goalId: "ID",
    journeyId: "ID",
    notes: "String",
    setDueTimeToStandardTaskSla: "Boolean",
    subtext: "String",
    tags: "String",
    title: "String"
  },
  TaskDestroy: {
    id: "ID"
  },
  TaskMarkRead: {
    id: "ID"
  },
  TaskToggleCompleted: {
    completed: "Boolean",
    id: "ID"
  },
  TaskUpdate: {
    action: "String",
    archived: "Boolean",
    attachmentIds: "ID",
    carePlanTaskTemplateId: "ID",
    category: "TaskCategoryInput",
    directUploadBlobIds: "ID",
    dueTime: "String",
    expectedStartDate: "String",
    expertId: "ID",
    id: "ID",
    journeyId: "ID",
    notes: "String",
    resourceFeatures: "ResourceFeatureInput",
    subtext: "String",
    tags: "String",
    title: "String"
  },
  TimeLogCreate: {
    activityKindId: "ID",
    date: "String",
    journeyId: "ID",
    minutes: "Int",
    notes: "String",
    scheduledMinutes: "Int",
    supportKindId: "ID"
  },
  TimeLogDestroy: {
    id: "ID"
  },
  TimeLogUpdate: {
    activityKindId: "ID",
    date: "String",
    id: "ID",
    minutes: "Int",
    notes: "String",
    scheduledMinutes: "Int",
    supportKindId: "ID"
  },
  TrackerStatusUpdate: {
    journeyId: "ID",
    status: "TrackerStatusEnum",
    type: "TrackerTypeEnum"
  },
  TwoFactorAuthenticationCreate: {
    channel: "PhoneNumberChannelEnum",
    formattedNumber: "String",
    number: "String",
    type: "TwoFactorAuthenticationTypeEnum"
  },
  TwoFactorAuthenticationVerify: {
    code: "String"
  },
  UpdateCommunityGroupMembership: {
    id: "ID",
    wantsNotifications: "Boolean"
  },
  WebinarRegistrationSubscription: {
    id: "ID"
  },
  WebinarRegistrationsCreate: {
    company: "String",
    email: "String",
    firstName: "String",
    journeyId: "ID",
    lastName: "String",
    recurrenceType: "WebinarRegistrationRecurrenceTypeEnum",
    webinarId: "ID"
  },
  WebinarRegistrationsDestroy: {
    id: "ID",
    recurrenceType: "WebinarRegistrationRecurrenceTypeEnum"
  },
  WorkEmailConfirmationSend: {
    email: "String"
  },
  WorkingHourBlockCreate: {
    day: "Int",
    endTime: "String",
    startTime: "String"
  },
  WorkingHourBlockDestroy: {
    id: "ID"
  },
  WorkingHourBlockUpdate: {
    day: "Int",
    endTime: "String",
    id: "ID",
    startTime: "String"
  },
  WorksheetCreate: {
    goalId: "ID",
    journeyId: "ID",
    worksheetModifications: "WorksheetItemModificationInput",
    worksheetTemplateId: "ID"
  },
  WorksheetDestroy: {
    id: "ID"
  },
  WorksheetTemplateCreate: {
    description: "String",
    focusAreas: "FocusAreaEnum",
    tags: "String",
    title: "String"
  },
  WorksheetTemplateItemCreate: {
    acceptsCheckbox: "Boolean",
    acceptsDetails: "Boolean",
    title: "String",
    worksheetTemplateId: "ID"
  },
  WorksheetTemplateItemDestroy: {
    id: "ID"
  },
  WorksheetTemplateItemUpdate: {
    acceptsCheckbox: "Boolean",
    acceptsDetails: "Boolean",
    id: "ID",
    title: "String"
  },
  WorksheetTemplateItemsReorder: {
    worksheetTemplateId: "ID",
    worksheetTemplateItemIds: "ID"
  },
  WorksheetTemplateUpdate: {
    archived: "Boolean",
    description: "String",
    focusAreas: "FocusAreaEnum",
    id: "ID",
    tags: "String",
    title: "String"
  },
  WorksheetUpdate: {
    completed: "Boolean",
    description: "String",
    folderId: "ID",
    id: "ID",
    title: "String",
    worksheetModifications: "WorksheetItemModificationInput"
  }
};

export default operationVariableTypes;
